//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



export default {

    props: [],
    components: {},
    data: function () {
        return {
            form: {
                returnDate: '',
                returnAmount: '',
                remark: ''
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            }
         
        }
    },
    computed: {

    },
    watch: {

    },

    methods: {
        cancle: function () {
            this.$emit('close');
        },

        submit: function () {
            console.log(this.form);
            this.$post(`${this.getGetters('settUrl')}/settReturnRecord`,this.form,function(data){
                ShowMsg('提交成功','success');
                this.cancle();
            })
        },

        checkReturnAmount: function (rule, value, callback) {
            let reg = /(^[1-9]([0-9]+)?(.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9].[0-9]([0-9])?$)/;
            if (!reg.test(value)) {
                callback(new Error('输入格式不正确'));
            } else {
                callback();
            }
        },

    },
    mounted () {

    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Detail from './components/detail.vue'
import returnedMoney from './components/returnedMoney.vue'

export default {
    mixins: [],
    props:[],
    components:{Detail,returnedMoney},
    data: function(){
        return{
            pgData:{
                startDate:'',
                endDate:'',
            },
            tableData:[],
            tableHeight:window.innerHeight - 238,

            pgExtra:{
                leijiweizhifu:{},
            },

            settStatusList:[
                // {value:'未结算',key:'0',type:'info'},
                // {value:'待审核',key:'1',type:'primary'},
                // {value:'审核退回',key:'2',type:'danger'},
                {value:'待结算',key:'3',type:'primary'},
                {value:'待确认',key:'4',type:'warning'},
                {value:'已确认',key:'5',type:'success'},
                {value:'报送异议',key:'6',type:'danger'},
            ],

           formTitle:'',
           detailDialog:false,
           returnedDialog:false,
        }
    }, 
    computed:{
        totalList:function(){
            return [
                {label:'应支付总金额：',num:this.pgExtra.yingzhifu},
                {label:'已支付总金额：',num:this.pgExtra.yizhifu},
                {label:'未支付总金额：',num:this.pgExtra.weizhifu},
                {label:'累计未支付总金额：',num:this.pgExtra.leijiweizhifu.leijiweizhifuAmount},
            ]   
        },
        valuetime:{
            get:function(){
                var startTime = this.pgData.startDate || '';
                var endtime = this.pgData.endDate || '';
                    if(startTime && endtime){
                        return[
                            startTime,
                            endtime
                        ];
                    }else{
                        return[]
                    }
            },
            set:function(val){
                if(!val){
                    this.pgData.startDate = '' ;
                    this.pgData.endDate = '';
                }else{
                    var startTime = val[0] || '';
                    var endtime = val[1] || '';
                    this.pgData.startDate = startTime;
                    this.pgData.endDate = endtime;
                }
            },
        }
    },
    watch:{
        returnedDialog:function(n,o){
            if(!n){
                this.doSearch();
            }
        },
    },
    methods: {
        doSearch:function(){
            this.$refs.page.queryData();
        },
        reload:function(){
            this.$refs.page.reload();
        },
        afterQuery:function(data,res){
            this.pgExtra = res.extra;
        },

        //查看明细
        showDetail:function(){
            this.formTitle = '明细';
            this.detailDialog = true;
        },
        //录入回款金额
        returnedHandler:function(){
            this.formTitle = '录入回款金额';
            this.returnedDialog = true;
        }
    },
    mounted() {

    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    mixins: [],
    props: {
        listdata: {
            type: Array,
            default:function(){
                return []
            },
        },
    },
    components: {  },
    data: function () {
        return {

        }
    },
    computed: {
      
    },
    watch: {

    },
    methods: {

    },
    mounted () {
    }
}
